import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

// This hook is called for every request on the client side

// configure Sentry (client side)
let enableSentry = false;
if (env.PUBLIC_SENTRY_ENVIRONMENT === 'production' || env.PUBLIC_SENTRY_ENVIRONMENT === 'staging') {
	enableSentry = true;
}
Sentry.init({
	enabled: enableSentry,
	environment: env.PUBLIC_SENTRY_ENVIRONMENT,
	dsn: 'https://a4f758b45d1b281d17ecf48637849bcd@o4505647075229696.ingest.sentry.io/4505647079555072',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		Sentry.replayIntegration(),
		Sentry.feedbackIntegration({
			showBranding: false,
			triggerLabel: 'Fehler melden',
			formTitle: 'Fehler melden',
			submitButtonLabel: 'Fehlermeldung absenden',
			cancelButtonLabel: 'Abbrechen',
			confirmButtonLabel: 'Bestätigen',
			addScreenshotButtonLabel: 'Screenshot hinzufügen',
			removeScreenshotButtonLabel: 'Screenshot entfernen',
			namePlaceholder: 'Name',
			emailPlaceholder: 'vorname.nachname@tu-dortmund.de',
			isRequiredLabel: '(Pflichtfeld)',
			messageLabel: 'Beschreibung des Fehlers',
			messagePlaceholder: 'Bitte beschreiben Sie den Fehler so genau wie möglich.',
			successMessageText: 'Vielen Dank für Ihre Meldung!'
		})
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
